import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreDetailPageComponent } from './store-detail-page/store-detail-page.component';
import { StoreModule } from '@ngrx/store';
import { MtnCoreModule } from '../core/mtn-core.module';
import { MtnRoutingModule } from '../core/modules/mtn-routing.module';
import { DETAIL_STATE_KEY, detailReducer } from './detail-state';
import { ChartsModule } from 'angular-bootstrap-md';
import { StoreCompetitiveAnalysisPanelComponent } from './store-detail-page/store-competitive-analysis-panel/store-competitive-analysis-panel.component';
import { StoreAnalystRatingPageComponent } from './store-detail-page/store-additional-insights-panel/analyst-ratings-card/store-analyst-rating-wizard/store-analyst-rating-page/store-analyst-rating-page.component';
import { SimpleServiceViewComponent } from './store-detail-page/store-additional-insights-panel/store-services-card/store-services-wizard/store-services-page/simple-service-view/simple-service-view.component';
import { AddComparisonPageComponent } from './store-detail-page/store-competitive-analysis-panel/add-comparison-wizard/add-comparison-page/add-comparison-page.component';
import { DemographicDetailsPageComponent } from './store-detail-page/store-demographic-profile-panel/demographic-details-wizard/demographic-details-page/demographic-details-page.component';
import { StoreHeaderPanelComponent } from './store-detail-page/store-header-panel/store-header-panel.component';
import { StoreSubpanelComponent } from './store-detail-page/store-header-panel/accordion-subpanel/store-subpanel/store-subpanel.component';
import { SpaceSubpanelComponent } from './store-detail-page/store-header-panel/accordion-subpanel/space-subpanel/space-subpanel.component';
import { ShoppingCenterSubpanelComponent } from './store-detail-page/store-header-panel/accordion-subpanel/shopping-center-subpanel/shopping-center-subpanel.component';
import { AccordionSubpanelComponent } from './store-detail-page/store-header-panel/accordion-subpanel/accordion-subpanel.component';
import { StoreDetailsPageComponent } from './store-detail-page/store-header-panel/accordion-subpanel/store-subpanel/store-details-wizard/store-details-page/store-details-page.component';
import { SpaceDetailsPageComponent } from './store-detail-page/store-header-panel/accordion-subpanel/space-subpanel/space-details-wizard/space-details-page/space-details-page.component';
import { ShoppingCenterDetailsPageComponent } from './store-detail-page/store-header-panel/accordion-subpanel/shopping-center-subpanel/shopping-center-details-wizard/shopping-center-details-page/shopping-center-details-page.component';
import { StoreAlertsPanelComponent } from './store-detail-page/store-alerts-panel/store-alerts-panel.component';
import { HistoricalStoresPageComponent } from './store-detail-page/store-alerts-panel/historical-stores-wizard/historical-stores-page/historical-stores-page.component';
import { StoreCriticalInsightsPanelComponent } from './store-detail-page/store-critical-insights-panel/store-critical-insights-panel.component';
import { CriticalInsightsCardComponent } from './store-detail-page/store-critical-insights-panel/critical-insights-card/critical-insights-card.component';
import { SalesAreaPageComponent } from './store-detail-page/store-critical-insights-panel/sales-area-wizard/sales-area-page/sales-area-page.component';
import { SalesVolumeHistoryPageComponent } from './store-detail-page/store-critical-insights-panel/sales-volume-history-wizard/sales-volume-history-page/sales-volume-history-page.component';
import { StatusHistoryPageComponent } from './store-detail-page/store-critical-insights-panel/status-history-wizard/status-history-page/status-history-page.component';
import { StoreSalesPerformancePanelComponent } from './store-detail-page/store-sales-performance-panel/store-sales-performance-panel.component';
import { CompetitiveAnalysisCardComponent } from './store-detail-page/store-sales-performance-panel/competitive-analysis-card/competitive-analysis-card.component';
import { CompetitiveAnalysisDetailsPageComponent } from './store-detail-page/store-sales-performance-panel/competitive-analysis-card/competitive-analysis-details-wizard/competitive-analysis-details-page/competitive-analysis-details-page.component';
import { StoreHighlightsPanelComponent } from './store-detail-page/store-critical-insights-panel/store-highlights-panel/store-highlights-panel.component';
import { StoreHighlightCardComponent } from './store-detail-page/store-critical-insights-panel/store-highlights-panel/store-highlight-card/store-highlight-card.component';
import { CompetitiveIncursionsPageComponent } from './store-detail-page/store-critical-insights-panel/store-highlights-panel/competitive-incursions-wizard/competitive-incursions-page/competitive-incursions-page.component';
import { StoreAdditionalInsightsPanelComponent } from './store-detail-page/store-additional-insights-panel/store-additional-insights-panel.component';
import { StoreServicesCardComponent } from './store-detail-page/store-additional-insights-panel/store-services-card/store-services-card.component';
import { AnalystRatingsCardComponent } from './store-detail-page/store-additional-insights-panel/analyst-ratings-card/analyst-ratings-card.component';
import { StoreServicesPageComponent } from './store-detail-page/store-additional-insights-panel/store-services-card/store-services-wizard/store-services-page/store-services-page.component';
import { StoreBannerAnalysisPanelComponent } from './store-detail-page/store-banner-analysis/store-banner-analysis-panel.component';
import { BannerAnalysisCardComponent } from './store-detail-page/store-banner-analysis/banner-analysis-card/banner-analysis-card.component';
import { BannerComparisonPageComponent } from './store-detail-page/store-banner-analysis/banner-analysis-card/banner-comparison-wizard/banner-comparison-page/banner-comparison-page.component';
import {
  RequestMoreStoreInsightFormPageComponent
} from './store-detail-page/store-alerts-panel/request-more-insight-wizard/request-more-store-insight-form-page/request-more-store-insight-form-page.component';
import { SampleStorePageComponent } from './sample-store-page/sample-store-page.component';
import { IconButtonPopUPComponent } from '../core/ui/icon-button-pop-up/icon-button-pop-up.component';
import { MtnMapModule } from '../map/mtn-map.module';


@NgModule({
  declarations: [
    StoreDetailPageComponent,
    StoreCompetitiveAnalysisPanelComponent,
    StoreAnalystRatingPageComponent,
    SimpleServiceViewComponent,
    AddComparisonPageComponent,
    DemographicDetailsPageComponent,
    StoreHeaderPanelComponent,
    StoreSubpanelComponent,
    SpaceSubpanelComponent,
    ShoppingCenterSubpanelComponent,
    AccordionSubpanelComponent,
    StoreDetailsPageComponent,
    SpaceDetailsPageComponent,
    ShoppingCenterDetailsPageComponent,
    StoreAlertsPanelComponent,
    HistoricalStoresPageComponent,
    StoreCriticalInsightsPanelComponent,
    CriticalInsightsCardComponent,
    SalesAreaPageComponent,
    SalesVolumeHistoryPageComponent,
    StatusHistoryPageComponent,
    StoreSalesPerformancePanelComponent,
    CompetitiveAnalysisCardComponent,
    CompetitiveAnalysisDetailsPageComponent,
    StoreHighlightsPanelComponent,
    StoreHighlightCardComponent,
    CompetitiveIncursionsPageComponent,
    StoreAdditionalInsightsPanelComponent,
    StoreServicesCardComponent,
    AnalystRatingsCardComponent,
    StoreServicesPageComponent,
    StoreBannerAnalysisPanelComponent,
    BannerAnalysisCardComponent,
    BannerComparisonPageComponent,
    RequestMoreStoreInsightFormPageComponent,
    SampleStorePageComponent,
    IconButtonPopUPComponent

  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(DETAIL_STATE_KEY, detailReducer),
    MtnCoreModule,
    MtnMapModule,
    MtnRoutingModule,
    ChartsModule
  ],
  exports:[
  ]
})
export class MtnDetailModule {
}
