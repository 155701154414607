import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsEventType } from '../../google-analytics/analytics-event-type';
import { MtnMap } from '../mtn-map';
import { Feature } from '../../core/models';
import { MapMode } from '../map-mode.enum';
import { BaseComponent } from '../../core/base-component';
import { filter } from 'rxjs/operators';
import { MapEvent, MapEventType } from '../map-events';

import LatLngBounds = google.maps.LatLngBounds;
import LatLngLiteral = google.maps.LatLngLiteral;
import MapTypeId = google.maps.MapTypeId;

@Component({
  selector: 'mtn-map-controls-toolbar',
  templateUrl: './map-controls-toolbar.component.html',
  styleUrls: ['./map-controls-toolbar.component.scss']
})
export class MapControlsToolbarComponent extends BaseComponent implements OnInit {

  @Input()
  map: MtnMap;
  @Input()
  envName:string;
  AnalyticsEventType = AnalyticsEventType;
  MapMode = MapMode;
  MapTypeId = MapTypeId;

  isFeatureMap = false;
  isMapTypeControlsEnabled = false;
  _isStoreAdding = false;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeToMapEvents();
  }

  centerOnSelection(): void {
    const points = this.map.selectedFeatures.map((feature: Feature) => feature.getGeometryAsLatLng());

    const bounds = new LatLngBounds();
    points.forEach((point: LatLngLiteral) => bounds.extend(point));

    this.map.fitBounds(bounds, 120);

    if (this.map.options.recenterOffsetX) {
      this.map.panBy(this.map.options.recenterOffsetX / 2, 0);
    }
  }

  toggleDarkMode(): void {
    this.map.setOptions({
      mode: this.map.options.mode === MapMode.CLASSIC ? MapMode.DARK : MapMode.CLASSIC,
    });
  }
  togglestoreAdd() {
    this._isStoreAdding = !this._isStoreAdding
    this.map.setOptions({
      _isStoreAdding: this._isStoreAdding ,
    });
  }

  toggleSatelliteView(): void {
    this.map.setOptions({
      mapTypeId: this.map.options.mapTypeId === MapTypeId.HYBRID ? MapTypeId.ROADMAP : MapTypeId.HYBRID
    });
  }

  private setControlFlags(): void {
    this.isFeatureMap = this.map.options.isFeatureMap;
    this.isMapTypeControlsEnabled = this.map.options.controlConfiguration.isMapModeEnabled
      || this.map.options.controlConfiguration.isMapTypeEnabled;
  }

  private subscribeToMapEvents(): void {
    this.addSubscription(
      this.map.events
        .pipe(filter((event: MapEvent<any>) => event.type === MapEventType.OPTION_CHANGE))
        .subscribe(() => {
          this.setControlFlags();
        })
    );
  }
}
