import { Component, Input, OnInit, ViewChild, NgZone, ChangeDetectorRef } from '@angular/core';
import { ShoppingCenter, Space, Store } from '../../../core/models';
import { SpinnerSize } from '../../../core/util/spinner/spinner-size.enum';
import { catchError, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { StoreService } from '../../../core/store/store.service';
import { DefinitionType } from '../../../core/definition/definition-type.enum';
import { DefinitionViewComponent } from '../../../core/definition/definition-display/definition-view.component';
import { forkJoin, Observable, of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { InsightsDatePipe } from '../../../core/date/insights-date.pipe';
import { SpaceService } from '../../../core/space/space.service';
import * as _ from 'lodash';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { LicenseType } from '../../../auth/authorization/license-type.enum';
import { FutureStoreStatuses, HistoricalStoreStatuses } from '../../../core/identity/constant/store-status-type.enum';
import { MtnMap } from '../../../map/mtn-map';
import { MapEvent, MapEventType, MapSelectionEvent } from '../../../map/map-events';
import { StoreVolume } from '../../../core/store/volume/store-volume';

@Component({
  selector: 'mtn-store-spotlight-panel',
  templateUrl: './store-spotlight-panel.component.html',
  styleUrls: ['../spotlight-panel.scss', './store-spotlight-panel.component.scss'],
  providers: [DatePipe, InsightsDatePipe]
})
export class StoreSpotlightPanelComponent extends AuthorizationAwareComponent implements OnInit {

  @Input()
  map: MtnMap;

  @ViewChild('shoppingCenterType')
  definitionShoppingCenterType: DefinitionViewComponent;

  DefinitionType = DefinitionType;
  isFailed = false;
  isFutureStore = false;
  isHistoricalStore = false;
  isLoading = false;
  isVisible = true;
  otherStores: Store[] = [];
  salesAreaPercentage: number;
  shoppingCenter: ShoppingCenter;
  space: Space;
  SpinnerSize = SpinnerSize;
  store: Store;
  volume: StoreVolume;

  private uuid: string;

  constructor(protected ngrxStore: NgrxStore<AppState>,
              private spaceService: SpaceService,
              private storeService: StoreService,
              private NgZone: NgZone,
              private cdr: ChangeDetectorRef
  ) {
    super(ngrxStore);
  }

  ngOnInit() {
    super.ngOnInit();

  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.uuid = [...this.map.selections][0];
    this.loadStore();
    this.subscribeToMapSelectionChangeEvents();
  }

  handleOtherStoreSelected(store: Store): void {
    this.uuid = store.uuid;
    this.loadStore();
  }

  loadStore(): void {
    this.isFailed = false;
    this.isFutureStore = false;
    this.isHistoricalStore = false;
    this.isLoading = true;
    this.isVisible = true;

    this.salesAreaPercentage = null;
    this.store = null;
    this.space = null;
    this.shoppingCenter = null;
    this.otherStores = [];

    this.storeService.findOne(this.uuid)
      .pipe(
        catchError((err) => {
          console.error(err);
          this.isFailed = true;
          return of(null);
        }),
        finalize(() => setTimeout(() => this.isLoading = false, 500)),
        switchMap((result: Store) => {
          this.store = result;
          this.space = result.space;
          this.shoppingCenter = result.space.shoppingCenter;

          if (this.hasLicense(LicenseType.STANDARD)) {
            this.isHistoricalStore = _.includes(HistoricalStoreStatuses, this.store.getCurrentStatus().getStatusSystemName());
            this.isFutureStore = _.includes(FutureStoreStatuses, this.store.getCurrentStatus().getStatusSystemName());

            const tasks: Observable<any>[] = [];
            /*Load other stores at this location*/
            tasks.push(
              this.spaceService.findOnesStores(this.space.uuid)
                .pipe(tap((results: Store[]) => {
                  this.otherStores = _.reject(results, (result: Store) => result.uuid === this.store.uuid);
                }))
            );

            /*Load this store's volumes*/
            tasks.push(
              this.storeService.findOnesBestVolume(this.store.uuid)
                .pipe(tap((result: StoreVolume) => this.volume = result))
            );

            return forkJoin(tasks);
          } else {
            this.storeService.fillWithDummyData(this.store, this.licenseType);
            return of(null);
          }

        })
      )
      .subscribe(() => {
        this.calculateSalesAreaPercentage();
      });
  }

  private calculateSalesAreaPercentage(): void {
    this.salesAreaPercentage = this.store.getSalesAreaPercentage();
  }

  private subscribeToMapSelectionChangeEvents(): void {
    this.addSubscription(
      this.map.events
        .pipe(filter((event: MapEvent<any>) => event.type === MapEventType.SELECTION_CHANGE))
        .subscribe((event: MapSelectionEvent) => {
          if (event.payload.size === 1) {
            this.NgZone.run(() => {
              this.uuid = [...event.payload][0];
              this.loadStore();
              this.cdr.detectChanges(); // Trigger change detection explicitly
            });
          } else {
            this.NgZone.run(() => {
              this.uuid = null;
              this.cdr.detectChanges();
            });
          }
        })
    );
  }

  closePanel(): void {
    this.isVisible = false; // Hide the panel

    this.NgZone.run(() => {
      this.uuid = null;

      // Clear all marker selections
      this.map.clearSelections();

      // Update marker appearance to reflect deselection
      this.map.updateMarkers();

      this.cdr.detectChanges(); // Ensure Angular updates the DOM
    });
  }

}
