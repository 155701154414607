<div class="map-container container" [ngClass]="{ready: isReady}" fxFlexFill>
  <div class="map" #mapContainer></div>

  <mtn-filter-toolbar [map]="map" *ngIf="map?.options.controlConfiguration.isFiltersEnabled"></mtn-filter-toolbar>

  <div class="map-controls-container" fxLayout="column" *ngIf="map"
       [ngClass]="{'street-view-active': map.getStreetView()?.getVisible(),
       'filters-enabled': map.options.controlConfiguration.isFiltersEnabled}">
    <mtn-map-controls-toolbar [envName]="envName" [map]="map"></mtn-map-controls-toolbar>
  </div>

  <div class="selection-advice-container" *ngIf="showSelectionAdvice" fxLayout="row">
    <div class="advice-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <fa-icon class="color-help" icon="info-circle"></fa-icon>
      <div [ngSwitch]="map.selectionMode">
        <div *ngSwitchCase="MapSelectionMode.MULTI_SELECT">Click on markers to add them to the selection. Hold Ctrl
          while clicking to remove them instead.
        </div>
        <div *ngSwitchCase="MapSelectionMode.LASSO">Draw around markers to add them to the selection. Hold Ctrl while
          drawing to add/remove sets of markers to/from the selection.
        </div>
        <div *ngSwitchCase="MapSelectionMode.RING">Enter a radius, then click on the map to add all markers within the
          set radius to the selection. Hold Ctrl while clicking to add/remove sets of markers to/from the selection.
        </div>
      </div>
    </div>
  </div>

  <div class="loading-indicator-container" [ngClass]="{'fade-out': !isLoadingFeatures}" fxLayoutAlign="center center">
    <img src="../../assets/images/cube-preloader.svg" height="90px" width="90px;" alt="Loading Indicator"/>
  </div>
  <div *ngIf="showDropPin && envName == 'test'" class="drop-pin " [ngClass]="addDropPin ? 'highlightClass' : '' " (click)="toggleDropPin()"  fxLayoutAlign="center center">
    Drop Pin to Project Grocery Sales
   <img height="20" width="20" src="/assets/images/location-svg.svg"/>
  </div>
</div>
