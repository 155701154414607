import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map.component';
import { MtnCoreModule } from '../core/mtn-core.module';
import { GoogleSearchComponent } from './google-search/google-search.component';
import { GoogleSearchPageComponent } from './filter-toolbar/google-search-wizard/google-search-page/google-search-page.component';
import { FilterToolbarComponent } from './filter-toolbar/filter-toolbar.component';
import { MapToolbarButtonComponent } from './map-toolbar-button/map-toolbar-button.component';
import { MapControlsToolbarComponent } from './map-controls-toolbar/map-controls-toolbar.component';
import { MapControlsLegendAssemblyComponent } from './map-controls-toolbar/map-controls-legend-assembly/map-controls-legend-assembly.component';
import { MapSelectionToolsAssemblyComponent } from './map-controls-toolbar/map-selection-tools-assembly/map-selection-tools-assembly.component';
import { GoogleSearchAssemblyComponent } from './filter-toolbar/google-search-assembly/google-search-assembly.component';
import { MapLayersAssemblyComponent } from './filter-toolbar/map-layers-assembly/map-layers-assembly.component';
import { SalesFiltersAssemblyComponent } from './filter-toolbar/sales-filters-assembly/sales-filters-assembly.component';
import { AreaFiltersAssemblyComponent } from './filter-toolbar/area-filters-assembly/area-filters-assembly.component';
import { BannerFilterAssemblyComponent } from './filter-toolbar/banner-filter-assembly/banner-filter-assembly.component';
import { FitFilterAssemblyComponent } from './filter-toolbar/fit-filter-assembly/fit-filter-assembly.component';
import { StatusFilterAssemblyComponent } from './filter-toolbar/status-filter-assembly/status-filter-assembly.component';
import { CollectionFilterAssemblyComponent } from './filter-toolbar/collection-filter-assembly/collection-filter-assembly.component';
import { AddOrEditSavedSearchPageComponent } from './filter-toolbar/add-or-edit-saved-search-wizard/add-or-edit-saved-search-page/add-or-edit-saved-search-page.component';
import { StoreAddComponent } from './store-details-wizrd/store-add/store-add.component';
import { MtnFormControlsModule } from '../core/form-controls/mtn-form-controls.module';


@NgModule({
  declarations: [
    MapComponent,
    GoogleSearchComponent,
    GoogleSearchPageComponent,
    FilterToolbarComponent,
    MapToolbarButtonComponent,
    MapControlsToolbarComponent,
    MapControlsLegendAssemblyComponent,
    MapSelectionToolsAssemblyComponent,
    GoogleSearchAssemblyComponent,
    MapLayersAssemblyComponent,
    SalesFiltersAssemblyComponent,
    AreaFiltersAssemblyComponent,
    BannerFilterAssemblyComponent,
    FitFilterAssemblyComponent,
    StatusFilterAssemblyComponent,
    CollectionFilterAssemblyComponent,
    AddOrEditSavedSearchPageComponent,
    StoreAddComponent
  ],
  exports: [
    MapComponent,
    GoogleSearchComponent
  ],
  imports: [
    CommonModule,
    MtnCoreModule,
    MtnFormControlsModule
  ]
})
export class MtnMapModule {
}
