import { Wizard } from "src/app/core/wizard/wizard";
import { StoreAddComponent } from "./store-add/store-add.component";

// new-popup-wizard.ts
export class StoreAddWizard extends Wizard<any> {
  key = 'store-add';
  pageTypeMap = {
    'store-add': StoreAddComponent,
  };

  startingPageKey = 'store-add';

  constructor() {
    super();
    this.width = 900; // Set a width for the popup
  }
}
