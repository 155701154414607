export class AnalyticsEventType {

  public static readonly CANCEL_WORKFLOW = 'cancel-workflow';
  public static readonly CLICK_BUTTON = 'click-button';
  public static readonly CLICK_LANDING_PAGE_NAVIGATION_BUTTON = 'click-landing-page-navigation-button';
  public static readonly CLICK_NAVIGATION_BUTTON = 'click-navigation-button';
  public static readonly CLOSE_WIZARD = 'close-wizard';
  public static readonly END_WORKFLOW = 'end-workflow';
  public static readonly LOG_IN = 'log-in';
  public static readonly LOG_OUT = 'log-out';
  public static readonly RECENTER_MAP = 'recenter-map';
  public static readonly TOGGLE_EXPANSION_PANEL = 'toggle-expansion-panel';
  public static readonly TOGGLE_MAP_CONTROL = 'toggle-map-control';
  public static readonly TOGGLE_MENU = 'toggle-menu';
  public static readonly TOUCH_FORM_FIELD = 'touch-form-field';
  public static readonly OPEN_WIZARD = 'open-wizard';
  public static readonly START_WORKFLOW = 'start-workflow';
  public static readonly USE_SAVED_SEARCH = 'use-saved-search';
  public static readonly TOGGLE_STORE_ADD_CONTROL = 'toggle-store-add-control';


}
