import { Component, ViewChild } from '@angular/core';
import { WizardPageComponent } from '../../../../../../core/wizard/wizard-page-component';
import { BannerComparisonWizard } from '../banner-comparison-wizard';
import { BannerComparison } from '../../../../../../core/banner/banner-comparison';
import { Banner } from '../../../../../../core/models';
import { Observable } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { finalize, take } from 'rxjs/operators';
import { isLicenseOfLevel, LicenseType } from '../../../../../../auth/authorization/license-type.enum';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { BannerRanking } from '../../../../../../core/banner/banner-ranking';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { UpgradeLicenseWizard } from '../../../../../../core/license/upgrade-license-wizard/upgrade-license-wizard';
import { WizardRunnerService } from '../../../../../../core/wizard/wizard-runner.service';
import { DetailState, selectDetailState } from '../../../../../detail-state';
import { AppState } from '../../../../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { KeyIndicatorType } from '../../../../../../core/user-preferences/key-indicator-type.enum';

@Component({
  selector: 'mtn-banner-comparison-page',
  templateUrl: './banner-comparison-page.component.html',
  styleUrls: ['./banner-comparison-page.component.scss']
})
export class BannerComparisonPageComponent extends WizardPageComponent<BannerComparisonWizard> {

  private readonly DEFAULT_COLUMNS: string[] = ['rank', 'name', 'value'];

  key = 'banner-comparison';

  comparison: BannerComparison;
  dataSource = new MatTableDataSource<BannerRanking>();
  displayedColumns: string[] = this.DEFAULT_COLUMNS;
  isLoading = false;
  KeyIndicatorType = KeyIndicatorType;
  licenseType: LicenseType;
  subjectBanner: Banner;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  constructor(private ngrxStore: NgrxStore<AppState>,
              private wizardRunner: WizardRunnerService) {
    super();
    this.title = 'Banner Analysis';
    this.closeButtonText = 'Close';
  }

  onLoad(): Observable<any> {
    this.comparison = this.wizard.model.comparison;
    this.licenseType = this.wizard.model.licenseType;
    this.subjectBanner = this.comparison.banner;

    this.title = `Banner Analysis: ${this.subjectBanner?.name || 'No Banner'}`;


    selectDetailState(this.ngrxStore)
      .pipe(
        take(1),
        finalize(() => this.isLoading = false)
      )
      .subscribe((state: DetailState) => {
        if (!!state.marketArea) {
          // @ts-ignore
          this.subTitle = `In the ${state.marketArea.getFeature().properties['NAME']} CBSA`;
        }
      });

    this.initForm();
    this.initTable();

    return super.onLoad();
  }

  hasLicense(type: LicenseType): boolean {
    return isLicenseOfLevel(this.licenseType, type);
  }

  openUpgradeLicenseWizard(): void {
    const wizard = new UpgradeLicenseWizard();
    this.wizardRunner.run(wizard);
  }

  private initComparisonData(): void {
    this.dataSource.data = this.comparison.rankings;
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      keyIndicator: new UntypedFormControl(this.wizard.model.comparison.keyIndicator)
    });

    this.subscribeToFormChanges();
  }

  private initTable(): void {
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.sort.disableClear = true;
      this.dataSource.paginator = this.paginator;

      this.dataSource.sortingDataAccessor = (data: BannerRanking, header: string): any => {
        switch (header) {
          case 'name':
            return data.banner.name;
          default:
            // @ts-ignore
            return data[header];
        }
      };

      this.initComparisonData();
    }, 50);
  }

  private loadBannerComparison(): void {
    this.isLoading = true;

    const formValue = this.form.getRawValue();

    selectDetailState(this.ngrxStore)
      .pipe(
        take(1),
        finalize(() => this.isLoading = false)
      ).subscribe((state: DetailState) => {
      switch (formValue.keyIndicator) {
        case KeyIndicatorType.SALES_SQFT:
          this.comparison = state.bannerComparisonSalesSqft;
          break;
        case KeyIndicatorType.SALES_VOLUME:
          this.comparison = state.bannerComparisonSalesVolume;
          break;
        case KeyIndicatorType.STORE_COUNT:
          this.comparison = state.bannerComparisonStoreCount;
          break;
      }

      this.initComparisonData();
    });
  }

  private subscribeToFormChanges(): void {
    this.addSubscription(
      this.form.get('keyIndicator').valueChanges
        .subscribe(() => {
          this.loadBannerComparison();
        })
    );
  }

}
