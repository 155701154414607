import { MtnMapOptions } from './mtn-map-options';
import { MapSelectionMode } from './map-selection-mode.enum';
import { FilterGroup } from '../core/federation/filter/filter-group';
import { FeatureCollection } from '../core/models';
import LatLngLiteral = google.maps.LatLngLiteral;
import MapTypeId = google.maps.MapTypeId;
import Marker = google.maps.Marker;
import Polygon = google.maps.Polygon;

export enum MapEventType {
  BOUNDS_CHANGE = 'BOUNDS_CHANGE',
  CLICK = 'CLICK',
  FILTER_CHANGE = 'FILTER_CHANGE',
  MAP_DRAW = 'MAP_DRAW',
  MAP_TYPE_CHANGE = 'MAP_TYPE_CHANGE',
  OPTION_CHANGE = 'OPTION_CHANGE',
  SELECTION_CHANGE = 'SELECTION_CHANGE',
  SELECTION_MODE_CHANGE = 'SELECTION_MODE_CHANGE',
  STORE_ADD_MODE =  'STORE_ADD_MODE'
}

export abstract class MapEvent<T> {
  private _payload: T;
  private _type: MapEventType;

  constructor(payload: T, type: MapEventType) {
    this._payload = payload;
    this._type = type;
  }

  get payload(): T {
    return this._payload;
  }

  get type(): MapEventType {
    return this._type;
  }
}

export class MapBoundsChangeEvent extends MapEvent<FeatureCollection> {

  constructor(payload: FeatureCollection) {
    super(payload, MapEventType.BOUNDS_CHANGE);
  }
}

export class MapClickEvent extends MapEvent<LatLngLiteral> {

  constructor(payload: LatLngLiteral) {
    super(payload, MapEventType.CLICK);
  }
}

export class MapDrawEvent extends MapEvent<Marker[] | Polygon[]> {

  constructor(payload: Marker[] | Polygon[]) {
    super(payload, MapEventType.MAP_DRAW);
  }
}

export class MapFilterChangeEvent extends MapEvent<FilterGroup> {

  constructor(payload: FilterGroup) {
    super(payload, MapEventType.FILTER_CHANGE);
  }
}

export class MapTypeChangeEvent extends MapEvent<MapTypeId | string> {

  constructor(payload: MapTypeId | string) {
    super(payload, MapEventType.MAP_TYPE_CHANGE);
  }
}

export class MapOptionChangeEvent extends MapEvent<MtnMapOptions> {

  constructor(payload: MtnMapOptions) {
    super(payload, MapEventType.OPTION_CHANGE);
  }
}

export class MapSelectionEvent extends MapEvent<Set<string>> {

  constructor(payload: Set<string>) {
    super(payload, MapEventType.SELECTION_CHANGE);
  }
}

export class MapSelectionModeChangeEvent extends MapEvent<MapSelectionMode> {

  constructor(payload: MapSelectionMode) {
    super(payload, MapEventType.SELECTION_MODE_CHANGE);
  }
}
