<h3 class="heading">Enter Key Details to Project Sales</h3>
<div class="store-field" [ngClass]="(isSubmitted && !isInitialized) ? 'disabled' : '' " [formGroup]="newUserForm">
  <mat-form-field (click)="grocerSelectionReset()" class="input-field" appearance="outline">
    <mat-label>Select Grocer</mat-label>
    <input
      type="text"
      matInput
      placeholder="Type to search"
      formControlName="grocer"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      panelClass="custom-autocomplete-panel"
      #auto="matAutocomplete"
      [displayWith]="displayGrocerName"
    >
      <mat-option
        *ngFor="let store of filteredStores | async"
        [value]="store.StoreName"
      >
        {{ store.StoreName }} ({{ store.DefaultFit }})
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="newUserForm.get('grocer').hasError('required')">
      <i>Must be provided</i>
    </mat-error>
  </mat-form-field>


  <mtn-number-field
    [form]="newUserForm"
    (click)="grocerSqftReset()"
    controlName="sqft"
    [isRequired]="true"
    class="input-field"
    placeholder="Select SQFT"
  ></mtn-number-field>

</div>

<div *ngIf="isSubmitted" class="demographic-view">

  <h1 class="mat-h1 color-primary text-small-caps no-bottom-margin">Summary Details</h1>
<hr>
  <div *ngIf="isInitialized" >
    <div class="summary-details">
      <div class="right-border border-bottom sales-comparison-widget-container container default-padding mat-elevation-z1 clickable"
           fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" matRipple >
        <div fxLayout="column" >
          <small class="color-muted">Projected Weekly Sales Range</small>
          <strong *ngIf="weeklySalesLowerRange && weeklySalesUpperRange">${{weeklySalesLowerRange | number:'1.2-2' }} - ${{weeklySalesUpperRange | number:'1.2-2'}}</strong>
          <strong *ngIf="!(weeklySalesLowerRange && weeklySalesUpperRange)">--</strong>
        </div>
      </div>

      <div class="right-border border-bottom sales-comparison-widget-container container default-padding mat-elevation-z1 clickable"
           fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" matRipple >
        <div fxLayout="column" >
          <small class="color-muted">Total Sales Projection </small>
          <strong class="color-light-green">{{ totalSalesProjectionStatus }}</strong>
          <small *ngIf="!bannerCBSAAverages?.cbsa" class="color-muted">Relative to Overall Banner Average</small>
          <small *ngIf="bannerCBSAAverages?.cbsa" class="color-muted">out of {{bannerCBSAAverages?.name}} in {{getStateAbbreviation(cbsaName)}} </small>

        </div>
      </div>
      <div class="left-border border-bottom sales-comparison-widget-container container default-padding mat-elevation-z1 clickable"
           fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" matRipple >
        <div fxLayout="column" >
          <small class="color-muted">Sqft Projection</small>
          <strong class="color-light-green">{{ sqftProjectionStatus }}</strong>
          <small *ngIf="!bannerCBSAAverages?.cbsa" class="color-muted">Relative to Overall Banner Average </small>
          <small *ngIf="bannerCBSAAverages?.cbsa" class="color-muted">out of {{bannerCBSAAverages?.name}} in {{getStateAbbreviation(cbsaName)}} </small>

        </div>
      </div>
    </div>
    <div class="summary-details">
      <div class="right-border border-top  sales-comparison-widget-container container default-padding mat-elevation-z1 clickable"
           fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" matRipple >
        <div fxLayout="column">
          <small class="color-muted">Nearest {{nearestStore?.bannerName}}</small>
          <strong>{{ nearestStore?.distance  | number:'1.2-2'}} mi </strong> <strong class="color-red" *ngIf="(densityClass == 'Urban' && nearestStore?.distance < 2) || (densityClass == 'Suburban' && nearestStore?.distance < 3) || (densityClass == 'Rural' && nearestStore?.distance < 5) "> Cannibalization Risk </strong>
        </div>
      </div>

      <div class="right-border border-top sales-comparison-widget-container container default-padding mat-elevation-z1 clickable"
           fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" matRipple >
        <div fxLayout="column" >
          <small class="color-muted">Closest Competitor</small>
          <strong>{{closetCompetitor.storeName}}-{{ closetCompetitor.distance  | number:'1.2-2'}} mi</strong>
        </div>
      </div>
      <div class="left-border border-top sales-comparison-widget-container container default-padding mat-elevation-z1 clickable"
           fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" matRipple >
        <div fxLayout="column" >
          <strong class="color-muted">Future openings</strong>
          <small>Count within 7 mins / closet</small>
          <strong>{{futureStoreCount}}    /  {{nearestFutureStoreDistance | number:'1.2-2'}} mi ({{nearestFutureStoreName}})</strong>
        </div>
      </div>
    </div>





  </div>
  <div class="loader-container" *ngIf="!isInitialized" fxLayoutAlign="center center">
    <img src="assets/images/cube-preloader.svg" height="90px" width="90px;" alt="Loading Indicator"/>
  </div>
</div>

<div [ngClass]="!isSubmitted ? 'hide' : '' "  class="demographic-view">
  <div class="comparison-detail-page-container container" fxLayout="column" fxLayoutGap="15px">
    <div  class="map-container p-3 mt-2 ">
      <mtn-map [options]="mapOptions"  (ready)="onMapReady($event)"></mtn-map>
    </div>
  </div>
</div>

<div  [ngClass]="!isSubmitted ? 'hide' : '' "  class="demographic-view">
  <h1 class="mat-h1 color-primary text-small-caps no-bottom-margin">Competitive Analysis</h1>

  <hr/>

  <div class="banner-comparison-page-container container" fxLayout="column" fxLayoutGap="15px">
    <div class="form-container" fxLayout="row" fxLayoutGap="15px">
      <div class="width100" fxLayout="row" fxLayoutAlign="space-between">
        <div class="form-container-left" fxLayout="row" fxLayoutGap="15px">
          <form [formGroup]="keyIndicatorForm">
            <mtn-key-indicator-field [form]="keyIndicatorForm"></mtn-key-indicator-field>
          </form>
        </div>
        <form  [formGroup]="form" >
          <div class="form-container-right" fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="15px">
            <mtn-region-field [form]="form" [isMarketAreaAvailable]="true"></mtn-region-field>
            <mtn-drive-time-minutes-field [form]="form"
                                          *ngIf="form.get('region').value === 'DRIVE_TIME'"></mtn-drive-time-minutes-field>
            <mtn-ring-miles-field [form]="form"
                                  *ngIf="form.get('region').value === 'RING'"></mtn-ring-miles-field>
          </div>
        </form>

      </div>
    </div>
    <div class="table-container condensed-table container">
      <div class="table-container condensed-table container">
        <table #table mat-table [dataSource]="dataSource" matSort matSortActive="banner" matSortDirection="asc" aria-label="Sales data table">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef scope="col" aria-label="Banner Name">Banner</th>
            <td mat-cell *matCellDef="let element" aria-label="Banner Name">{{element.name}}</td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" aria-label="Total Sales">
              <span *ngIf="keyIndicatorForm.value.keyIndicator == 'SALES_VOLUME'">Total Sales</span>
              <span *ngIf="keyIndicatorForm.value.keyIndicator == 'SALES_SQFT'">Sales Per SQFT</span>
            </th>
            <td mat-cell *matCellDef="let element" aria-label="Total Sales">$ {{element.value | number:'1.2-2'}}</td>
          </ng-container>

          <ng-container matColumnDef="totalCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" aria-label="Total Count">Count</th>
            <td mat-cell *matCellDef="let element" aria-label="Total Count">{{element.totalCount}}</td>
          </ng-container>

          <!-- "No Data Available" Row -->
          <tr  *matNoDataRow >
            <td [attr.colspan]="displayedColumns.length" class="no-data" role="row">
                No data available
            </td>
        </tr>


          <!-- Header Row -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

          <!-- Data Rows -->
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" role="row"></tr>
        </table>


        <mat-paginator
        #paginator
          [pageSize]="10"
          [length]="totalLength"
          [pageSizeOptions]="[5, 10, 20]"
          [showFirstLastButtons]="true"
          aria-label="Table paginator">
        </mat-paginator>

      </div>
    </div>
    <mtn-spinner [size]="SpinnerSize.MEDIUM" [on]="isLoading"></mtn-spinner>
  </div>
</div>

<div [ngClass]="!isSubmitted ? 'hide' : '' " class="demographic-view">
  <div class="comparison-detail-page-container container" fxLayout="column" fxLayoutGap="15px">
  <mtn-store-demographic-profile-panel [coardinates]="this.wizard.model" [storeAnalysys]="true"></mtn-store-demographic-profile-panel>
  </div>
</div>








