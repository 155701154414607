import { Injectable } from '@angular/core';
import { IsochroneRequest } from './isochrone-request';
import { Observable } from 'rxjs';
import { FeatureCollection } from '../models';
import { map } from 'rxjs/operators';
import { InsightsRestService } from '../service/insights-rest-service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IsochroneService extends InsightsRestService {

  constructor(protected http: HttpClient) {
    super();
  }

  findOneIsochrone(request: IsochroneRequest): Observable<FeatureCollection> {
    const url = this.buildUrl('/isochrone');

    return this.http.post(url, request)
      .pipe(map((result: any) => new FeatureCollection(result)));
  }

}
