import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ShapeService } from 'src/app/core/depot/shape.service';
import { SpatialShapeType } from 'src/app/core/depot/spatial-shape-type.enum';
import { FeatureCollection } from 'src/app/core/models';
import { RestService } from 'src/app/core/service/rest-service';
import { environment } from 'src/environments/environment';
import { Store as NgrxStore } from '@ngrx/store';
import { AppState } from 'src/app/app-state';
import { DetailSetMarketAreaAction } from 'src/app/detail/detail-actions';

@Injectable({
  providedIn: 'root'
})
export class StoreAddService extends RestService {

  protected host = environment.AWS_SERVICE_HOST;
  protected rootPath = '/dev/predict';
  protected averagePath = '/averages';

  private readonly apiKey = 'Vfb40RT1Z41Wum4HbrarW1dFKMgOu5XP9rUZBQSu'; // API Key
  private apiUrl = `${environment.SERVICE_HOST}/api/insights/store/nearest-store`;
  private futureStoreUrl = `${environment.SERVICE_HOST}/api/insights/store/future-store`;
  private bannerUrl = `${environment.SERVICE_HOST}/api/insights/banner`;

  constructor(private http: HttpClient, private shapeService : ShapeService,
    private ngrxStore: NgrxStore<AppState>,

  ) {
    super();
  }

  /**
   * Fetch weekly sales range by calling the external API.
   * @param requestPayload The payload to send to the API.
   * @returns Observable containing API response.
   */
  getWeeklySalesRange(requestPayload: {
    long: number;
    lat: number;
    total_area: number;
    banner: string;
  }): Observable<any> {
    const url = this.buildUrl(); // Use root path from RestService
    const headers = new HttpHeaders({
      'x-api-key': this.apiKey,
      'Content-Type': 'application/json',
    });

    return this.http.post(url, requestPayload, { headers });
  }

  getBannerData(requestPayload: {
    geoJson: any;
    comparisonType: string;
    keyIndicator: string;
    salesSqftDisplayType: string;
    salesVolumeDisplayType:string;
  }): Observable<any> {


    return this.http.post(this.bannerUrl + this.averagePath, requestPayload);
  }

  getFutureStore(requestPayload: {
    lat: number;
    lng: number;
  }): Observable<any> {
    return this.http.post(this.futureStoreUrl, {point:requestPayload});
  }

  getBannerCBSAAverages(bannerUuid: number, requestPayload:{
    fips: string;
    salesSqftDisplayType: string,
    salesVolumeDisplayType: string
  }):Observable<any> {
    return this.http.post(this.bannerUrl + "/" + bannerUuid + this.averagePath, requestPayload)
  }


  getNearestStore(bannerId: number, latitude: number, longitude: number, bannerFlag: boolean){
    // Set the parameters for the API call
    let params = new HttpParams()
      .set('bannerId', bannerId.toString())
      .set('latitude', latitude.toString())
      .set('longitude', longitude.toString())
      .set('bannerFlag', bannerFlag.toString());

    // Make the HTTP GET request and return the Observable
    return this.http.get(this.apiUrl, { params });
  }

  loadMarketArea(coardinates:any): Observable<FeatureCollection> {
    console.log("🚀 ~ StoreAddService ~ loadMarketArea ~ coardinates:", coardinates)
    const request = {
      latitude: coardinates.lat,
      longitude: coardinates.lng,
      type: SpatialShapeType.CBSA
    };

    return this.shapeService.searchIntersectingShape(request)
      .pipe(tap((result: FeatureCollection) => {
        console.log("🚀 ~ StoreAddService ~ .pipe ~ result:", result)
        this.ngrxStore.dispatch(DetailSetMarketAreaAction({area: result}));
        // return result;
      }));
  }

}
