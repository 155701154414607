<div class="number-field-container container" [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>{{placeholder}}</mat-label>
    <input
      matInput
      type="number"
      [formControlName]="controlName"
      [required]="isRequired"
      (keydown)="blockNonNumeric($event)"
    />
    <mat-error *ngIf="form.get(controlName).hasError('max')">
      <i>Cannot exceed {{form.get(controlName).getError('max').max.toLocaleString()}}</i>
    </mat-error>
    <mat-error *ngIf="form.get(controlName).hasError('min')">
      <i>Must be at least {{form.get(controlName).getError('min').min.toLocaleString()}}</i>
    </mat-error>
    <mat-error *ngIf="form.get(controlName).hasError('required')">
      <i>Must be provided</i>
    </mat-error>
  </mat-form-field>
</div>
