import { Banner, FeatureCollection, Store } from './../../../core/models';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';
import { StoreAddWizard } from '../store-add-wizard';
import { WizardPageComponent } from 'src/app/core/wizard/wizard-page-component';
import { StoreAddService } from '../store-add.service';
import { StoreDetailService } from 'src/app/core/store/store-detail.service';
import { Store as NgrxStoreType } from '@ngrx/store';
import { AppState } from 'src/app/app-state';
import { RegionType } from 'src/app/core/location/region-type.enum';
import { ComparisonType } from 'src/app/core/store/ranking/comparison-type.enum';
import { KeyIndicatorType } from 'src/app/core/user-preferences/key-indicator-type.enum';
import { LicenseType } from 'src/app/auth/authorization/license-type.enum';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BannerService } from 'src/app/core/banner/banner.service';
import { IsochroneService } from 'src/app/core/depot/isochrone.service';
import { CircleRequest } from 'src/app/core/shared/shape/circle-request';
import { ReportService } from 'src/app/core/service/report.service';
import { metersToMiles } from 'src/app/core/util/math-utils';
import { FeatureType } from 'src/app/core/federation/feature/feature-type.enum';
import Marker = google.maps.Marker;
import { MtnMap } from '../../mtn-map';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SalesSqftDisplayType } from 'src/app/core/user-preferences/sales-sqft-display-type.enum';
import { SalesVolumeDisplayType } from 'src/app/core/user-preferences/sales-volume-display-type.enum';
import { ProjectionStatus } from './projection-status.enum';

@Component({
  selector: 'mtn-store-add',
  templateUrl: './store-add.component.html',
  styleUrls: ['./store-add.component.scss'],
})
export class StoreAddComponent
  extends WizardPageComponent<StoreAddWizard>
  implements OnInit, AfterViewInit
{
  key = 'store-add'; // Unique key for this wizard page
  newUserForm: FormGroup;
  keyIndicatorForm: FormGroup;
  stores: Array<{ StoreName: string; DefaultFit: string }> = [];
  filteredStores: Observable<{ StoreName: string; DefaultFit: string }[]>;
  mapOptions: any;
  isInitialized = false;
  isSubmitted = false;
  weeklySalesUpperRange: any;
  weeklySalesLowerRange: any;
  closetCompetitor: any;
  nearestStore: any;
  store: Store;
  volume: any;
  userPreferences: any;
  _marketArea: any;
  comparisonStoreCount: any;
  comparisonSalesVolume: any;
  comparisonSalesSqft: any;
  coardinates: any;
  isActionButtonEnabled: boolean = true;
  private readonly VALIDATORS_DRIVE_TIME = [
    Validators.min(1),
    Validators.max(20),
  ];
  private readonly VALIDATORS_RING = [Validators.min(1), Validators.max(10)];
  private readonly DEFAULT_COLUMNS: string[] = ['name', 'value', 'totalCount'];
  selectedStore: string = '';
  selectedStoreUUID: number = 1;
  selectedStoreID: number = 1;
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = this.DEFAULT_COLUMNS;
  isLoading = false;
  KeyIndicatorType = KeyIndicatorType;
  licenseType: LicenseType;
  subjectBanner: Banner;
  marketAreaCoardinates: FeatureCollection;
  driveTimeCoardinates: any = '';
  ringMilesCoardinates: any = '';
  bannerData: any = '';
  bannerCBSAAverages: any;
  map: MtnMap;
  futureStoreStatus = ['NEW_CONSTRUCTION', 'PLANNED', 'PROPOSED'];
  nearestFutureStoreDistance = 0;
  nearestFutureStoreName = '--';
  futureStoreCount = 0;
  markerClusterer: MarkerClusterer;
  private readonly DEFAULT_CLUSTER_ZOOM = 9;
  densityClass: string;
  totalLength: number = 0;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;

  totalSalesProjectionStatus = '--';
  sqftProjectionStatus = '--';
  cbsaName: any = '';
  bannerCoardinates: any;
  constructor(
    protected ngrxStore: NgrxStoreType<AppState>,
    private fb: FormBuilder,
    private http: HttpClient,
    public storeAddService: StoreAddService,
    public storeDetailService: StoreDetailService,
    private bannerService: BannerService,
    private isochrone: IsochroneService,
    private reportService: ReportService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initWizardButtons();
    this.initializeForms();
    this.mapInitialization();
    this.buildMarkerClusterer();
    this.fetchGrocerFromCSV();
    this.loadMarketArea();
    this.bannerFormChnagesSubscription();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  initWizardButtons() {
    this.title = this.wizard.model.location;
    this.closeButtonText = 'close';
    this.hideActionSpinner = true;
    this.actionButtonText = 'Project Grocery Sales';
    this.coardinates = this.wizard.model;
  }
  initializeForms() {
    this.newUserForm = this.fb.group({
      sqft: [
        5000,
        [Validators.required, Validators.min(5000), Validators.max(250000)],
      ],
      grocer: ['', Validators.required],
    });
    this.form = new UntypedFormGroup({
      comparisonType: new UntypedFormControl(ComparisonType.ALL, [
        Validators.required,
      ]),
      driveTimeMinutes: new UntypedFormControl(7, this.VALIDATORS_DRIVE_TIME),
      region: new UntypedFormControl(RegionType.MARKET_AREA, [
        Validators.required,
      ]),
      ringMiles: new UntypedFormControl(3, this.VALIDATORS_RING),
    });
    this.keyIndicatorForm = this.fb.group({
      keyIndicator: new UntypedFormControl(KeyIndicatorType.SALES_SQFT, [
        Validators.required,
      ]),
    });
  }
  mapInitialization() {
    this.mapOptions = {
      key: 'map-search-2',
      center: { lat: this.wizard.model.lat, lng: this.wizard.model.lng },
      featureType: FeatureType.STORE,
      zoom: 14,
      controlConfiguration: {
        isGoogleSearchEnabled: false,
        isFiltersEnabled: false,
        isMapModeEnabled: false,
        isMapTypeEnabled: false,
        isRecenterEnabled: false,
        isStreetViewEnabled: false,
        isTiltAndRotateEnabled: false,
        isZoomEnabled: true,
      },
      onMarkerClick: (marker: Marker) => this.handleMarkerClick(marker),
    };
  }
  fetchGrocerFromCSV() {
    this.http
      .get('/assets/images/Store Banner List for P&C 9-9-2024.csv', {
        responseType: 'text',
      })
      .subscribe((data) => {
        const rows = data.split('\n').slice(1); // Skip header
        this.stores = rows.map((row) => {
          const [StoreName, DefaultFit] = row.split(',');
          return { StoreName, DefaultFit };
        });
        this.initializeAutocomplete();
      });
  }

  bannerFormChnagesSubscription() {
    this.addSubscription(
      this.form.valueChanges
        .pipe(debounceTime(300))
        .subscribe((formValue: any) => {
          if (formValue.region == 'MARKET_AREA') {
            this.loadMarketArea();
          } else if (formValue.region == 'DRIVE_TIME') {
            this.load7minutes(formValue.driveTimeMinutes);
          } else if (formValue.region == 'RING') {
            this.loadRingMiles(formValue.ringMiles);
          }
        })
    );
    this.keyIndicatorForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.callBannerData();
    });
  }

  addMarker(lat: number, lng: number): void {
    const newMarker = new google.maps.Marker({
      position: { lat, lng },
      map: this.map,
    });

    // Add the marker to the clusterer if necessary
    if (this.markerClusterer) {
      this.markerClusterer.addMarker(newMarker);
    } else {
      // If not using a clusterer, add it directly to the map
      newMarker.setMap(this.map);
    }
  }
  handleMarkerClick(marker: Marker): void {
    this.map.select(marker.get('id'));
  }
  buildMarkerClusterer(): void {
    this.markerClusterer = new MarkerClusterer(this.map, [], {
      averageCenter: true,
      imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      maxZoom: this.DEFAULT_CLUSTER_ZOOM,
    });
  }

  loadCBSAAveragesForBanner() {
    this.isLoading = true;
    const request: any = {
      fips: this.marketAreaCoardinates.getFeature().id,
      salesSqftDisplayType: SalesSqftDisplayType.SALES_AREA,
      salesVolumeDisplayType: SalesVolumeDisplayType.WEEKLY,
    };
    this.storeAddService
      .getBannerCBSAAverages(this.selectedStoreUUID, request)
      .subscribe({
        next: (result: any) => {
          this.isLoading = false;
          this.bannerCBSAAverages = result;
        },
        error: (err: any) => {
          this.isLoading = false;
          console.error('Error loading CBSA Averages:', err);
        },
      });
  }

  loadMarketArea() {
    this.isLoading = true;
    this.storeAddService.loadMarketArea(this.wizard.model).subscribe({
      next: (result: any) => {
        this.marketAreaCoardinates = result;
        this.cbsaName =
          this.marketAreaCoardinates?.getFeature()?.properties?.name;
        result.features[0].properties ={};
        this.bannerCoardinates = result;
        this.callBannerData();
      },
      error: (err: any) => {
        this.isLoading = false;
        console.error('Error loading market area:', err);
      },
    });
  }
  load7minutes(minutes: number) {
    this.isLoading = true;
    const request: any = {
      point: {
        lat: this.wizard.model.lat,
        lng: this.wizard.model.lng,
      },
      travelTimeMinutes: minutes,
    };

    this.isochrone.findOneIsochrone(request).subscribe({
      next: (result: FeatureCollection) => {
        this.driveTimeCoardinates = result;
        this.bannerCoardinates = result;
        this.callBannerData();
      },
      error: (err: any) => {
        this.isLoading = false;
        console.error('Error loading market area:', err);
      },
    });
  }
  loadRingMiles(miles: number) {
    this.isLoading = true;
    const circleRequest: CircleRequest = new CircleRequest();
    circleRequest.point = {
      type: 'Point',
      coordinates: [this.wizard.model.lat, this.wizard.model.lng],
    };
    circleRequest.distanceMiles = miles;
    return this.reportService.createOne(circleRequest).subscribe({
      next: (result: FeatureCollection) => {
        this.ringMilesCoardinates = result;
        this.bannerCoardinates = this.convertToGeoJSON(result);
        this.callBannerData();
      },
      error: (err: any) => {
        this.isLoading = false;
        console.error('Error loading market area:', err);
      },
    });
  }

  callBannerData() {
    this.loadData(this.bannerCoardinates).subscribe({
      next: (result: any) => {
        if (result.length > 0) {
          const { average, totalStores } =
            this.calculateWeightedAverage(result);
          this.totalLength = result.length + 1;
          this.dataSource.data = [
            {
              uuid: 1,
              name: 'All Grocery',
              value: average,
              totalCount: totalStores,
            },
            ...result,
          ];
          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.table.renderRows();
          });
        } else {
          this.totalLength = 0;
          this.dataSource.data = [];
        }

        this.isLoading = false;
      },
      error: (err: any) => {
        this.totalLength = 0;
        this.dataSource.data = [];
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });

        this.isLoading = false;
        console.error('Error fetching demographic data:', err);
      },
    });
  }

  loadData(marketAreaCoardinate: any) {
    const request: any = {
      geoJson: marketAreaCoardinate,
      comparisonType: 'ALL',
      keyIndicator: this.keyIndicatorForm.value.keyIndicator,
      salesSqftDisplayType: 'SALES_AREA',
      salesVolumeDisplayType: 'WEEKLY',
    };

    return this.storeAddService.getBannerData(request);
  }

  private initializeAutocomplete(): void {
    this.filteredStores = this.newUserForm.get('grocer')!.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterGrocers(value || ''))
    );
  }

  private filterGrocers(
    value: string
  ): { StoreName: string; DefaultFit: string }[] {
    const filterValue = value.toLowerCase();
    return this.stores.filter((store) =>
      store.StoreName.toLowerCase().includes(filterValue)
    );
  }

  displayGrocerName(storeName: string): string {
    return storeName || '';
  }

  onAction(): any {
    if (!this.newUserForm.valid) {
      console.error('Form is invalid');
      return;
    }
    this.futureStoreCount = 0;
    this.isActionButtonEnabled = false;
    this.isSubmitted = true;
    this.isInitialized = false;

    this.resetSalesRanges();
    this.fetchclosetCompetitor(
      1,
      this.wizard.model.lat,
      this.wizard.model.lng,
      false
    );
    this.fetchWeeklySalesRange();
    this.fetchFutureStores();

    if (this.selectedStore != this.newUserForm.value.grocer) {
      this.selectedStore = this.newUserForm.value.grocer;
      this.onGrocerSelected(this.newUserForm.value.grocer);
    }
  }

  fetchFutureStores() {
    this.storeAddService
      .getFutureStore({
        lat: this.wizard.model.lat,
        lng: this.wizard.model.lng,
      })
      .subscribe(
        (response) => this.handleFutureStoresResponse(response),
        (error) => this.handleError(error)
      );
  }

  handleFutureStoresResponse(response: any) {
    response.forEach((element: any) => {
      if (this.futureStoreStatus.includes(element.currentStatus)) {
        const newDistance = element.distance;
        if (this.nearestFutureStoreDistance < newDistance) {
          this.nearestFutureStoreDistance = newDistance;
          this.nearestFutureStoreName = element.storeName;
        }
        this.futureStoreCount++;
      }
    });
  }

  resetSalesRanges(): void {
    this.weeklySalesUpperRange = undefined;
    this.weeklySalesLowerRange = undefined;
  }

  grocerSelectionReset() {
    this.isSubmitted = false;
    this.isActionButtonEnabled = true;
    this.newUserForm.controls['grocer'].reset();
  }
  grocerSqftReset(){
    this.isSubmitted = false;
    this.isActionButtonEnabled = true;
  }

  private fetchWeeklySalesRange(): void {
    const { lng, lat } = this.wizard.model;
    const { sqft, grocer } = this.newUserForm.value;

    this.storeAddService
      .getWeeklySalesRange({
        long: lng,
        lat: lat,
        total_area: sqft,
        banner: grocer,
      })
      .subscribe(
        (response) => this.handleWeeklySalesResponse(response),
        (error) => this.handleError(error)
      );
  }

  private handleWeeklySalesResponse(response: any): void {
    this.isInitialized = true;
    // this.densityClass = response?.data[0]?.density_class;
    if (response?.predict?.ensemble_lower && response?.predict?.ensemble_upper && response.data.length>0 ) {
      const densityTypes = ['Rural', 'Suburban', 'Urban'];
      const densityClassWithPostfix = response?.data[0]?.density_class || '';

      this.densityClass =
        densityTypes.find((type) => densityClassWithPostfix.startsWith(type)) ||
        null;
      const { ensemble_lower, ensemble_upper } = response?.predict;
      const areaSales = response.data[0].area_sales;
      this.weeklySalesLowerRange = ensemble_lower;
      this.weeklySalesUpperRange = ensemble_upper;
      this.calculateBannerProjection(ensemble_lower, ensemble_upper, areaSales);
    }else{
      this.sqftProjectionStatus = '--';
      this.totalSalesProjectionStatus = '--';
      this.weeklySalesLowerRange = undefined;
      this.weeklySalesUpperRange = undefined;

    }
  }

  private calculateBannerProjection(
    ensemble_lower: number,
    ensemble_upper: number,
    area_sales: number
  ) {
    if (
      ensemble_lower &&
      ensemble_upper &&
      area_sales &&
      this.bannerCBSAAverages
    ) {
      const lowerTotalSales = Number((ensemble_lower * area_sales).toFixed(2));
      const upperTotalSales = Number((ensemble_upper * area_sales).toFixed(2));
      const lowerSalesPerSqft = Number(ensemble_lower.toFixed(2));
      const upperSalesPerSqft = Number(ensemble_upper.toFixed(2));
      const { averageTotalSales, averageSalesPerSquareFoot } =
        this.bannerCBSAAverages;

      // Determine total sales projection status
      this.totalSalesProjectionStatus = this.calculateProjectionStatus(
        averageTotalSales,
        lowerTotalSales,
        upperTotalSales
      );

      // Determine square footage sales projection status
      this.sqftProjectionStatus = this.calculateProjectionStatus(
        averageSalesPerSquareFoot,
        lowerSalesPerSqft,
        upperSalesPerSqft
      );
    }
  }

  private calculateProjectionStatus(
    value: number,
    lowerValue: number,
    upperValue: number
  ): ProjectionStatus {
    if (value > lowerValue && value < upperValue) {
      return ProjectionStatus.Average;
    } else if (value > upperValue) {
      return ProjectionStatus.BelowAverage;
    } else {
      return ProjectionStatus.AboveAverage;
    }
  }

  private fetchclosetCompetitor(
    bannerId: number,
    latitude: number,
    longitude: number,
    bannerFlag: boolean
  ): void {
    this.storeAddService
      .getNearestStore(bannerId, latitude, longitude, bannerFlag)
      .subscribe(
        (stores: any) => {
          stores.distance = metersToMiles(stores.distance);

          this.closetCompetitor = stores;
        },
        (error) => this.handleError(error)
      );
  }
  private fetchNearestStore(
    bannerId: number,
    latitude: number,
    longitude: number,
    bannerFlag: boolean
  ): void {
    this.storeAddService
      .getNearestStore(bannerId, latitude, longitude, bannerFlag)
      .subscribe(
        (stores: any) => {
          stores.distance = metersToMiles(stores.distance);
          this.nearestStore = stores;
        },
        (error) => this.handleError(error)
      );
  }

  convertToGeoJSON(data: any): any {
    const updatedData = JSON.parse(JSON.stringify(data));
    updatedData.features.forEach((feature: any) => {
      if (feature.geometry && feature.geometry.coordinates) {
        feature.geometry.coordinates = feature.geometry.coordinates.map(
          (polygon: any) =>
            polygon.map((coordinate: any) => [coordinate[1], coordinate[0]]) // Switch [lat, lon] to [lon, lat]
        );
      }
    });

    return updatedData;
  }

  private handleError(error: any): void {
    this.isInitialized = true;
    console.error('API Error:', error);
  }
  onMapReady(map: MtnMap): void {
    this.map = map;
    if (this.map) {
      this.map.options.recenterOffsetX = 450; //Width of spotlight panel
      this.addMarker(this.wizard.model.lat, this.wizard.model.lng);
    }
  }

  onGrocerSelected(selectedValue: string): void {
    const request: any = {
      query: selectedValue.trim(),
    };
    this.bannerService.search(request).subscribe({
      next: (result: any) => {
        this.selectedStoreUUID = result.content[result.content.length - 1].uuid;
        this.selectedStoreID = result.content[result.content.length - 1].id;
        this.fetchNearestStore(
          this.selectedStoreID,
          this.wizard.model.lat,
          this.wizard.model.lng,
          true
        );
        // load banner CBSA averages
        this.loadCBSAAveragesForBanner();
      },
      error: (err) => {
        this.isLoading = false;
        console.error('Error fetching demographic data:', err);
      },
    });
    // Handle your logic here for the final selected value
  }

  calculateWeightedAverage(data: any) {
    let totalSales = 0;
    let totalStores = 0;

    data.forEach((item: any) => {
      totalSales += item.value * item.totalCount; // Sum of (Value × TotalCount)
      totalStores += item.totalCount; // Sum of TotalCount
    });

    return { average: totalSales / totalStores, totalStores }; // Weighted Average
  }

  getStateAbbreviation(cbsaName: string): string {
    const parts = cbsaName?.split(',');
    return parts?.length > 1 ? parts[1].trim() : cbsaName;
  }
}
