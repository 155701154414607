import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mtn-number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['./number-field.component.scss']
})
export class NumberFieldComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() controlName: string;
  @Input() isRequired = false;
  @Input() placeholder: string;

  JSON = JSON;

  constructor() { }

  ngOnInit(): void { }
  blockNonNumeric(event: KeyboardEvent): void {

    if (event.key == 'e' || event.key == 'E') {
      event.preventDefault();
    }
  }

}
