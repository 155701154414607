import { Component, OnInit } from '@angular/core';
import { LicenseType } from '../../../auth/authorization/license-type.enum';
import { filter } from 'rxjs/operators';
import { Store } from '../../../core/models';
import * as _ from 'lodash';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { SpaceService } from '../../../core/space/space.service';
import { DetailState, selectDetailState } from '../../detail-state';
import { HistoricalStoreStatuses } from '../../../core/identity/constant/store-status-type.enum';
import { WizardRunnerService } from '../../../core/wizard/wizard-runner.service';
import { HistoricalStoresWizard } from './historical-stores-wizard/historical-stores-wizard';
import { RequestMoreStoreInsightWizard } from './request-more-insight-wizard/request-more-store-insight-wizard';
import { StoreVolume } from '../../../core/store/volume/store-volume';

@Component({
  selector: 'mtn-store-alerts-panel',
  templateUrl: './store-alerts-panel.component.html',
  styleUrls: ['./store-alerts-panel.component.scss']
})
export class StoreAlertsPanelComponent extends AuthorizationAwareComponent implements OnInit {


  alertsCount = 1;
  hasHistoricalStores = false;
  isHistoricalStore = false;
  otherStores: Store[] = [];
  store: Store;
  volumeHistory: StoreVolume[] = [];


  private _isOtherStoresInitialized = false;

  constructor(protected ngrxStore: NgrxStore<AppState>,
              private spaceService: SpaceService,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.loadStore();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  openHistoricalStoresWizard(): void {
    const wizard = new HistoricalStoresWizard();
    wizard.model = {
      otherStores: this.otherStores
    };

    this.wizardRunner.run(wizard);
  }

  openRequestMoreInsightWizard(): void {
    const wizard = new RequestMoreStoreInsightWizard();
    wizard.model = {
      store: this.store,
      userProfile: this.currentUser
    };

    this.wizardRunner.run(wizard);
  }

  private loadOtherStores(): void {
    if (!this._isOtherStoresInitialized && this.hasLicense(LicenseType.STANDARD)) {
      this._isOtherStoresInitialized = true;
      this.spaceService.findOnesStores(this.store.space.uuid)
        .subscribe((results: Store[]) => {
          if (results.length) {
            const filteredStores = _.reject(results, (result: Store) => result.uuid === this.store.uuid);
            this.otherStores = _.orderBy(filteredStores, (store: Store) => {
              return store.getCurrentStatus().statusDate;
            }, ['desc']);

            this.isHistoricalStore = _.includes(HistoricalStoreStatuses, this.store.getCurrentStatus().getStatusSystemName()) && !!this.otherStores.length;
            this.hasHistoricalStores = !this.isHistoricalStore && !!this.otherStores.length;

            if (this.isHistoricalStore) {
              this.alertsCount++;
            }
            if (this.hasHistoricalStores) {
              this.alertsCount++;
            }
          } else {
            this.otherStores = [];
          }
        });
    }

  }

  private loadStore(): void {
    this.addSubscription(
      selectDetailState(this.ngrxStore)
        .pipe(filter((state: DetailState) => !!state.store))
        .subscribe((state: DetailState) => {
          this.store = state.store;
          this.volumeHistory = state.volumeHistory;
          this.loadOtherStores();
        })
    );
  }


}
